<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                @click="editTranslate(['consolidationUnion_stateWarningText'])"></span>
          <div class="fsz18 color-red font-weight-bold">{{$t('consolidationUnion_stateWarningText.localization_value.value')}}</div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center mt-4"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                @click="editTranslate(['common_close'])"></span>

        <MainButton
            :value="$t('common_close.localization_value.value')"
            class="buy-label__btn-i wfc "
            @click.native="$emit('close')"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";

  export default {
    name: "WrongStateWarningPopup",

    components: {
      Modal,
      MainButton,
    },

  }
</script>

<style lang="scss">

</style>
