import {ConsolidationUnion} from "@/components/modules/ConsolidationUnionModule/models/ConsolidationUnion";

export const consolidationUnionMixin = {

	methods: {

		checkPermission(){
			// return this.checkOrderTypePermission({
			// 	types: {
			//
			// 	},
			// 	currentType: this.$route.params.type
			// })
			return true
		},

		approveOrder(ids, edit) {
			let text = {
				title: 'common_AreYouSure',
				txt: '',
				customTxt: 'This order(s) is not scanned: #' + ids.join(', #'),
				yes: 'common_yes',
				no: 'common_confirmNo'
			}

			let confirm = () => {

				this.saveConsolidationUnion(edit, true)

				this.$store.commit('setConfirmDeletePopup', false)
				return true
			}

			this.deleteFunc(text, confirm)
		},

		reloadOrder() {
			this.loaded = false
			this.CU = new ConsolidationUnion()
			this.initConsolidationUnionEdit()
		},

		saveConsolidationUnion(edit = false, save = false) {

			if (!this.CU.firstValidationAdmin()) return

			let data = this.CU.prepareSave()
			let saveType = 'createConsolidationUnion'

			if (edit) {
				data = {
					id: this.CU.getId(),
					data: data
				}
				saveType = 'updateConsolidationUnion'
			}

			if (save) {
				data.data['save'] = 1
			}

			return this.$store.dispatch(saveType, data).then(response => {
				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						let orderIds = response.data?.order_ids;

						if (orderIds && orderIds.length > 0) {
							this.approveOrder(orderIds, edit)
							return
						}

						if (edit) {
							this.openNotify('success', 'common_notificationRecordChanged')
							this.reloadOrder()
						} else {
							this.openNotify('success', 'common_notificationRecordCreated')
							this.$router.push(this.$store.getters.GET_PATHS.reportsConsolidation)
						}

						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})

		},
	}
}
