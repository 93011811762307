<template>
  <div class="fragment" ref="reportScanBlock">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['consolidationUnion_scanNumber'])"></div>
        <SearchInput
            ref="reportScanInput"
            class="scan-ico"
            :label="$t('consolidationUnion_scanNumber.localization_value.value')"
            v-model="scanValue"
            @submit="scan($event)"
            @keyup.native="scan($event, true)"
        />
        <div class="color-red pt-2"
             v-if="scanErrorItem !== ''"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidationUnion_notFound'])"></div>
          {{"'" + scanErrorItem + "'"}} {{$t('consolidationUnion_notFound.localization_value.value')}}
        </div>
      </div>

      <div class="custom-col custom-col--33 custom-col--md-100">
        <div class="order-create__footer-btn ml-auto"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                    'common_create',
                    'common_save',
                    ])"></div>
          <MainButton
              class="order-create__footer-btn-i"
              :value="isCreate ? $t('common_create.localization_value.value') : $t('common_save.localization_value.value')"
              @click.native="$emit('saveConsolidationUnion')"
          />
        </div>
      </div>
    </div>
    <div class="order-create__row custom-row">
      <div v-if="CU.getRelatedOrders().length > 0" class="custom-col custom-col--66 custom-col--md-100">
        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidationUnion_order'])"></div>
          <table class="site-table">
            <thead>
            <tr>
              <th v-if="check" class="pr-0"></th>
              <th class="white-space-line">{{$t('consolidationUnion_order.localization_value.value')}}</th>
              <th align="right" class="pr-0"></th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in CU.getRelatedOrders()"
                :key="index"
            >
              <td v-if="check" class="pr-0">
                <DefaultCheckbox
                    class="checkbox-scoped"
                    :value="item.checked"
                    @input="item.checked = !item.checked"
                />
              </td>
              <td>
                <div class="table-row font-weight-bold">
                  #{{item.id}}: {{item.customer_name}}
                </div>
              </td>
              <td align="right" class="pr-0">
                <div class="table-right table-manage-list justify-content-end">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton" class="w160 right ">
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :target="'_blank'"
                          :type="'edit'"
                          :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.type).name + '/' + item.id"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeRelatedOrder(index)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in CU.getRelatedOrders()"
                 :key="index"
            >
              <div class="site-table-mobile__item">
                <div class="site-table-mobile__head">
                  <div class="site-table-mobile__head-checkbox">
                    <DefaultCheckbox
                        :value="item.checked"
                        @input="item.checked = !item.checked"
                    />
                  </div>
                  #{{item.id}}: {{item.customer_name}}
                </div>
                <div class="site-table-mobile__row custom-row">

                  <div class="site-table-mobile__piece custom-col">
                    <div class="table-right table-manage-list">
                      <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton" class="mw-100">
                        <template slot="item">
                          <LinkButton
                              :value="$t('common_edit.localization_value.value')"
                              :target="'_blank'"
                              :type="'edit'"
                              :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.type).name + '/' + item.id"
                          />
                        </template>
                        <template slot="item">
                          <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removeRelatedOrder(index)"
                          />
                        </template>
                      </ManagerButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import ManagerButton from "../../../../UI/buttons/ManagerButton/ManagerButton";
import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
import SearchInput from "../../../../UI/inputs/SearchInput/SearchInput";
import {FBMMixinsHelper} from "../../../../../mixins/FBMMixins/FBMMixins";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";

export default {
  name: "ScannerBlock",
  components: {
    MainButton,
    SearchInput,
    LinkButton,
    ManagerButton,
    DefaultCheckbox,
  },

  mixins: [mixinDetictingMobile, FBMMixinsHelper],

  props: {
    CU: Object,
    check: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      scanValue: '',
      scanErrorItem: '',
    }
  },

  mounted() {

    setTimeout(() => {
      //[0] is an input
      if (this.$refs?.reportScanInput?.$el?.children?.length) {
        this.$refs.reportScanInput.$el.children[0].querySelector('.search-input__input').focus()
      }
      if (this.$refs?.reportScanBlock) {
        this.$refs.reportScanBlock.scrollIntoView({behavior: 'smooth'})
      }
    }, 300)

  },

  methods: {
    removeRelatedOrder(index) {
      this.CU.removeRelatedOrders(index)
    },

    scan(e, checkPressedKey = false) {

      if (checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') return

      this.scanErrorItem = ''
      let requestType = 'fetchSearchUnionOrder'
      let queryString = `?scan_number=${this.scanValue}`

      this.$store.dispatch(requestType, queryString).then((response) => {

        let respData = this.getRespData(response)

        if (respData.length) {
          let foundItem = respData[0]

          let foundItemIndex = this.CU.data.relatedOrders.findIndex(item => {
            return +item.id === +foundItem?.object?.id
          })

          let checkIncludes = foundItemIndex !== -1

          if (this.CU.getSendState() !== null && this.CU.getSendState()?.code.toLowerCase() !== foundItem?.object?.send_from_region.toLowerCase()) {
            this.scanValue = ''
            this.$emit('showWarningWrongStatePopup')
            return
          }

          if (foundItem && !checkIncludes) {
            this.CU.addRelatedOrders({...foundItem.object, checked: false})
          }
          if (this.check && checkIncludes) {
            this.CU.data.relatedOrders[foundItemIndex].checked = !this.CU.data.relatedOrders[foundItemIndex].checked
          }

          this.scanValue = ''
        }
        else {
          this.scanErrorItem = this.scanValue
        }

      }).catch(error => this.createErrorLog(error))
    },
  }
}
</script>

<style scoped>

</style>